import { Controller } from 'stimulus';

export default class extends Controller {
  selectedClass = 'btn--state-selected';

  hiddenClass = 'hidden';

  static targets = ['element', 'input', 'yes', 'no', 'checkbox'];

  toggle(event) {
    event.preventDefault();
    const element = this.elementTarget;
    if (event.target.value) {
      element.classList.remove(this.hiddenClass);
    } else {
      element.classList.add(this.hiddenClass);
    }
  }

  toggleAndUpdate(event) {
    event.preventDefault();
    const element = this.elementTarget;

    if (element) {
      if (this.is_hidden(element)) {
        element.classList.remove(this.hiddenClass);
        this.updateCheckbox(event);
      } else {
        this.clear_hidden_input();
        element.classList.add(this.hiddenClass);
        this.updateCheckbox(event);
      }
    }
  }

  updateCheckbox(event) {
    event.preventDefault();
    const yesButton = this.yesTarget;
    const noButton = this.noTarget;
    const checkbox = this.checkboxTarget;
    if (this.is_selected(noButton) && event.target === yesButton) {
      noButton.classList.remove(this.selectedClass);
      yesButton.classList.add(this.selectedClass);
      checkbox.checked = true;
    } else if (this.is_selected(yesButton) && event.target === noButton) {
      yesButton.classList.remove(this.selectedClass);
      noButton.classList.add(this.selectedClass);
      checkbox.checked = false;
    }
  }

  is_hidden(element) {
    return element.className.includes(this.hiddenClass);
  }

  is_selected(button) {
    return button.className.includes(this.selectedClass);
  }

  clear_hidden_input() {
    const input = this.inputTarget;
    input.value = '';
  }
}
