import { Controller } from 'stimulus';
import LocalTime from 'local-time';

LocalTime.start();

export default class extends Controller {
  static targets = ['form', 'status', 'submit', 'amount', 'paymentButton'];

  connect() {
    this.timeout = null;
    this.duration = this.data.get('duration') || 1000;
  }

  save() {
    const form = this.formTarget;
    const status = this.statusTarget;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (status) {
        status.textContent = 'Saving...';
        window.Rails.fire(form, 'submit');
      }
    }, this.duration);
  }

  success(event) {
    event.preventDefault();
    const amountValue = this.amountTarget.value;
    const amount = this.formatAmount(amountValue);
    const now = LocalTime.strftime(new Date(), '%B %d, %Y %l:%m%P');
    this.setStatus(`${amount} on ${now}.`);
    this.paymentButtonTarget.removeAttribute('disabled');
    this.paymentButtonTarget.setAttribute('data-payment-id', event.detail[0]);
    this.paymentButtonTarget.setAttribute('data-amount', amount);
  }

  enable(event) {
    event.preventDefault();
    const formSubmit = this.submitTarget;
    formSubmit.removeAttribute('disabled');
  }

  error() {
    this.setStatus('There was a problem saving your payment request, please try again.');
  }

  setStatus(message) {
    if (this.hasStatusTarget) {
      this.statusTarget.textContent = message;
    }
    clearTimeout(this.timeout);
  }

  formatAmount(amount) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(amount);
  }
}
