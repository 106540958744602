import { Controller } from 'stimulus';
/* eslint-disable no-param-reassign */
export default class extends Controller {
  static targets = ['modality', 'frequency'];

  static values = { modalities: Array, frequencies: Array };

  updateModality() {
    const modality_id = this.modalityTarget.value;
    const modalities = this.modalitiesValue;
    const modality = this.getModalityObject(modality_id, modalities);
    const frequencies_array = this.frequenciesForModality(modality[0]);
    const select = this.frequencyTarget;
    this.setFrequencyOptions(select, frequencies_array);
  }

  getModalityObject(modality_id, modalities) {
    return modalities.filter((modality) => toString(modality_id) === toString(modality.id));
  }

  frequenciesForModality(modality) {
    return this.frequenciesValue.filter((frequency) => toString(frequency[1]) === toString(modality.frequency_id));
  }

  setFrequencyOptions(select, options) {
    if (select) {
      select.innerHTML = '';
      select.options[0] = new Option();
      options.forEach((option) => {
        select.options[select.options.length] = new Option(option[0], option[1]);
      });
    }
  }
}
/* eslint-enable no-param-reassign */
