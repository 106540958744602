import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['details', 'expand'];

  expand() {
    this.detailsTarget.classList.remove('h-28', 'overflow-hidden');
    this.expandTarget.classList.add('hidden');
  }

  collapse() {
    this.detailsTarget.classList.add('h-28', 'overflow-hidden');
    this.expandTarget.classList.remove('hidden');
  }
}
