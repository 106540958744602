import { Controller } from 'stimulus';
import * as Sentry from '@sentry/browser';

/**
 * Controller for the Sentry integration.
 *
 * This exposes the global `Sentry` object after initializing it so it can be accessed from any other controller
 * JavaScript code that wants to log an error or set state. Refer to the
 * [Sentry JavaScript docs](https://docs.sentry.io/platforms/javascript/)
 */
export default class extends Controller {
  static targets = ['config', 'user'];

  connect() {
    const el = this.configTarget;
    const sentryConfig = JSON.parse(el.dataset.sentryConfig);
    const user = JSON.parse(this.userTarget.dataset.user);
    Sentry.init(sentryConfig);
    Sentry.setUser(user);
    // Create a global Sentry variable so it can be used from anywhere.
    window.Sentry = Sentry;
  }
}
