import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['ctaButton', 'availableTimesContainer', 'selectedIntakeAppointment', 'allAvailableAppointments'];

  connect() {
    const { _paq } = window;
    const location = String(window.location.href);
    if (this.locationInIntakeFlow(location)) {
      _paq.push(['trackEvent', 'Intake', location]);
    }
    if (location.indexOf('appointments') !== -1) {
      if (!this.hasAvailableTimesContainerTarget) {
        _paq.push([
          'trackEvent',
          'PresentedAvailableIntakeAppointments',
          'all_available_appointments',
          this.allAvailableAppointmentsTarget.dataset.times,
        ]);
      }
    }
  }

  trackIntakeOrDownloadEvent() {
    const { _paq } = window;
    const dataset = Object.assign(this.ctaButtonTarget.dataset);
    dataset.location = window.location.href;
    _paq.push([dataset.eventName, dataset]);
  }

  trackAppointmentsEvent() {
    const { _paq } = window;
    const dataset = {
      eventName: this.selectedIntakeAppointmentTarget.dataset.eventName,
      all_available_appointments: this.availableTimesContainerTarget.dataset.availableAppointments,
      selected_intake_appointment: this.selectedIntakeAppointmentTarget.dataset.selectedTime,
    };
    dataset.location = window.location.href;
    _paq.push([
      dataset.eventName,
      'trackAppointmentsEvent',
      this.availableTimesContainerTarget.dataset.availableAppointments,
      this.selectedIntakeAppointmentTarget.dataset.selectedTime,
    ]);
  }

  locationInIntakeFlow(location) {
    return (
      location.indexOf('onboarding_assessments') !== -1 ||
      location.indexOf('appointments') !== -1 ||
      location.indexOf('more_resources') !== -1 ||
      location.indexOf('members/new') !== -1 ||
      location.indexOf('account_information') !== -1
    );
  }
}
