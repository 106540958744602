import consumer from './consumer';

const DocumentRequestChannel = consumer.subscriptions.create(
  { channel: 'DocumentRequestChannel', room: 'document_request' },
  {
    received({ content }) {
      if (content.data.document === 'photo_id') {
        const requestButton = document.getElementById('requestPhotoId');
        if (requestButton) {
          requestButton.innerText = 'Photo ID requested';
        }
      } else if (content.data.document === 'insurance') {
        const requestButton = document.getElementById('requestInsurance');
        if (requestButton) {
          requestButton.innerText = 'Insurance requested';
        }
      } else if (content.data.document === 'treatment_plan') {
        const requestButton = document.getElementById('requestTreatmentPlan');
        if (requestButton) {
          requestButton.innerText = 'Care plan signature requested';
        }
      }
    },
  }
);

export default DocumentRequestChannel;
