import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const { _paq } = window;
    const memberId = this.data.get('memberid');
    const { userAgent } = window.navigator;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
      if (memberId) {
        _paq.push(['iOSAppStoreRedirect', 'appRedirect', 'iOSAppStoreRedirect', 'iOSAppStoreRedirect', memberId]);
      }
      window.location.href = 'https://apps.apple.com/us/app/groups-recover-together/id1546116496';
    }
    if (userAgent.match(/android/i)) {
      if (memberId) {
        _paq.push([
          'AndroidAppStoreRedirect',
          'appRedirect',
          'AndroidAppStoreRedirect',
          'AndroidAppStoreRedirect',
          memberId,
        ]);
      }
      window.location.href = 'https://play.google.com/store/apps/details?id=com.groupsrecovertogether';
    }
  }
}
