import consumer from './consumer';

const PaymentRequestChannel = consumer.subscriptions.create(
  { channel: 'PaymentRequestChannel', room: 'payment_request' },
  {
    received() {
      const requestButton = document.getElementById('request-payment');

      if (requestButton) {
        requestButton.innerText = 'Payment requested';
      }
    },
  }
);

export default PaymentRequestChannel;
