import { Controller } from 'stimulus';

export default class extends Controller {
  format(event) {
    const currentSchedule = event?.target?.dataset?.scheduleId;

    const selectedIndex = event?.target?.selectedIndex;
    const selectedOption = event?.target[selectedIndex];
    const updatedTimezone = selectedOption?.getAttribute('location_time_zone');
    const updatedTimezoneAltName = this.getAlternativeName(updatedTimezone);

    const timezones = document.getElementById(currentSchedule)?.options;
    const timezonesArray = Array.from(timezones);

    const found = timezonesArray.find((item) => updatedTimezoneAltName.includes(item.value?.split('/')[1]));
    found.selected = true;
    found.setAttribute('selected', true);
  }

  formatNew(event) {
    const selectedIndex = event?.target?.selectedIndex;
    const selectedOption = event?.target[selectedIndex];
    const updatedTimezone = selectedOption?.getAttribute('location_time_zone');
    const updatedTimezoneAltName = this.getAlternativeName(updatedTimezone);

    const timezones = document.getElementsByClassName('schedules-timezone-field-new-modal')[0]?.options;
    const timezonesArray = Array.from(timezones);

    const found = timezonesArray.find((item) => updatedTimezoneAltName.includes(item.value?.split('/')[1]));
    found.selected = true;
    found.setAttribute('selected', true);
  }

  // HELPER
  getAlternativeName(timeZone) {
    const timeZoneOpts = {
      'America/New_York': 'US/Eastern',
      'America/Los_Angeles': 'US/Pacific',
      'America/Denver': 'US/Mountain',
      'America/Phoenix': 'US/Arizona',
      'America/Chicago': 'US/Central',
    };
    return timeZoneOpts[timeZone];
  }
}
