import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'status'];

  connect() {
    this.timeout = null;
    this.duration = this.data.get('duration') || 1000;
  }

  save() {
    const form = this.formTarget;
    const status = this.statusTarget;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (status) {
        status.textContent = 'Saving draft...';
      }
      window.Rails.fire(form, 'submit');
    }, this.duration);
  }

  success() {
    this.setStatus(`Draft saved ${new Date(Date.now()).toLocaleString()}`);
  }

  error() {
    this.setStatus('There was a problem saving your draft.');
  }

  setStatus(message) {
    if (this.hasStatusTarget) {
      this.statusTarget.textContent = message;

      this.timeout = setTimeout(() => {
        this.statusTarget.textContent = '';
      }, 2000);
    }
  }
}
