import { Controller } from 'stimulus';
import HelloSign from 'hellosign-embedded';

export default class extends Controller {
  static targets = ['finishButton', 'progressIndicator', 'errorMessageContainer', 'errorMessageText'];

  showProgress() {
    const progress = this.progressIndicatorTarget;
    const btn = this.finishButtonTarget;

    btn.classList.add('pointer-events-none');
    btn.classList.add('bg-opacity-60');
    progress.classList.remove('hidden');
  }

  hideProgress() {
    const progress = this.progressIndicatorTarget;
    const btn = this.finishButtonTarget;

    btn.classList.remove('pointer-events-none');
    btn.classList.remove('bg-opacity-60');
    progress.classList.add('hidden');
  }

  connect() {
    const btn = this.finishButtonTarget;

    btn.addEventListener('ajax:success', (event) => {
      const [_data, _status, xhr] = event.detail;
      const signatureData = JSON.parse(xhr.responseText);
      if (signatureData.sign_url) {
        this.signDocument(signatureData);
      } else {
        window.location.reload();
      }
    });
  }

  signDocument(signatureData) {
    const client = new HelloSign();
    const signUrl = signatureData.sign_url;
    const clientID = signatureData.client_id;

    client.open(signUrl, {
      clientId: clientID,
      skipDomainVerification: true,
    });

    client.on('sign', (_data) => {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });

    client.on('decline', (_data) => {
      this.hideProgress();
    });

    client.on('reassign', (_data) => {
      this.hideProgress();
    });

    client.on('cancel', (_data) => {
      this.hideProgress();
    });

    client.on('error', ({ code, signatureId }) => {
      this.showError(code, signatureId);
    });
  }

  showAjaxError(error) {
    this.showError(null, null, `Ajax error ${JSON.stringify(error.detail)}`);
  }

  showError(code, signatureId, errorDetail) {
    window.Sentry.captureMessage(
      `HelloSign error ${signatureId ?? '<no signatureId>'}: ${JSON.stringify(code)}: ${JSON.stringify(errorDetail)}`
    );
    this.hideProgress();
    if (this.hasErrorMessageContainerTarget && this.hasErrorMessageTextTarget) {
      this.errorMessageContainerTarget.classList.remove('hidden');
      let message =
        "We ran into an issue getting your care plan ready to sign. Don't worry, we have all your care plan info saved. Please try again.";
      if (typeof code === 'string' || typeof code === 'number') {
        message += ` (${code})`;
      }

      if (this.hasErrorMessageTextTarget) {
        this.errorMessageTextTarget.innerText = message;
      }
    }
  }
}
