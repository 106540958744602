import { Controller } from 'stimulus';
import HelloSign from 'hellosign-embedded';
import consumer from '../channels/consumer';

// Send a message to the WebView.
function dispatchMessage(message) {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(message);
  }
}

export default class extends Controller {
  static targets = ['signatureData', 'thankYouMessage', 'errorContainer'];

  connect() {
    if (
      ['sign-uploaded-document', 'sign-treatment-consent', 'sign-hipaa-consent', 'sign-annual-consent'].includes(
        this.signatureDataTarget.id
      )
    ) {
      this.SignedDocumentChannel = consumer.subscriptions.create('SignedDocumentChannel');
    }
  }

  signDocument() {
    const client = new HelloSign();
    const signatureData = JSON.parse(this.signatureDataTarget.dataset.info);
    const signUrl = signatureData.sign_url;
    const clientID = signatureData.client_id;
    const requestID = this.signatureDataTarget.dataset.requestId;
    const documentType = this.signatureDataTarget.id;

    const handleError = (err) => {
      if (window.Sentry) {
        window.Sentry.captureException(err);
      }

      // If we have added a modal/button to prompt user to refresh, then unhide it.
      // Otherwisse we will just refresh the window.
      if (this.hasErrorContainerTarget) {
        this.errorContainerTarget.classList.remove('hidden');
        this.signatureDataTarget.classList.add('hidden');
      } else {
        client.close();
        window.location.reload();
      }
    };

    try {
      client.open(signUrl, {
        clientId: clientID,
        skipDomainVerification: true,
        timeout: 30000,
      });
    } catch (error) {
      handleError(error);
    }

    client.on('message', (message) => {
      if (message.type === 'hellosign:error') {
        handleError(message.payload);
      }
    });

    client.on('cancel', (_data) => {
      client.close();
      window.location.reload();
    });

    client.on('sign', (_data) => {
      if (documentType === 'sign-treatment-plan') {
        client.close();
        const thankYouText = document.querySelector('#thankYouText');
        if (thankYouText) {
          thankYouText.classList.remove('hidden');
          dispatchMessage('completed-care-plan-signature-request');
        }
        const signatureButton = document.querySelector('#signatureButton');
        if (signatureButton) signatureButton.classList.add('hidden');
      }

      if (['sign-treatment-consent', 'sign-hipaa-consent', 'sign-annual-consent'].includes(documentType)) {
        this.signatureDataTarget.classList.add('hidden');

        this.SignedDocumentChannel.perform('signed', {
          member_id: this.signatureDataTarget.dataset.memberId,
          document_type: documentType,
        });
      }

      if (this.signatureDataTarget.id === 'sign-uploaded-document') {
        this.SignedDocumentChannel.perform('signed', { id: requestID });
        this.signatureDataTarget.classList.add('hidden');
        this.thankYouMessageTarget.classList.remove('hidden');
      }
    });

    client.on('error', (data) => {
      handleError(data);
    });
  }

  reloadPage() {
    window.location.reload();
  }
}
