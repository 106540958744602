import { Controller } from 'stimulus';

function isValidPhoneNumber(input) {
  // The rules are here: https://en.wikipedia.org/wiki/North_American_Numbering_Plan#Modern_plan
  if (!/^[2-9]\d\d[2-9]\d{6}$/.test(input)) {
    return false;
  }
  if (input[1] === input[2] || input.slice(4, 6) === '11' || input.slice(3, 6) === '988') {
    return false;
  }
  return true;
}

export default class extends Controller {
  static classes = ['invalid'];

  static targets = ['error'];

  format(event) {
    // Source: https://stackoverflow.com/a/68822305
    const el = event.target;
    // Remove an initial 1 and any non-digit characters. Limit length to 10 digits
    const normalizedInput = el.value.replace(/^1+|\D/g, '').slice(0, 10);
    let result = normalizedInput;
    const size = normalizedInput.length;
    if (size > 0) {
      result = `(${result}`;
    }
    if (size > 3) {
      result = `${result.slice(0, 4)}) ${result.slice(4, 11)}`;
    }
    if (size > 6) {
      result = `${result.slice(0, 9)}-${result.slice(9)}`;
    }

    el.value = result;

    if (size === 10 && !isValidPhoneNumber(normalizedInput)) {
      el.classList.add(this.invalidClass);
      if (this.hasErrorTarget) {
        this.errorTarget.classList.remove('hidden');
      }
    } else {
      el.classList.remove(this.invalidClass);
      if (this.hasErrorTarget) {
        this.errorTarget.classList.add('hidden');
      }
    }
  }
}
