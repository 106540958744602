import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button'];

  success() {
    if (this.hasButtonTarget) {
      this.buttonTarget.textContent = 'Completed';
    }
  }

  error() {
    if (this.hasButtonTarget) {
      this.buttonTarget.textContent = 'Error';
    }
  }
}
