// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

/* eslint-disable import/no-unresolved */
import 'controllers';
import '../stylesheets/application.scss';
import '../stylesheets/admin.scss';
import '../stylesheets/base.scss';
import '../stylesheets/buttons.scss';
import '../stylesheets/modals.scss';
import '../stylesheets/pagination.scss';
import '../stylesheets/select.scss';
import '../stylesheets/tables.scss';
import '../stylesheets/admin-progress.scss';
import '../stylesheets/admin-search.scss';
import '../stylesheets/flatpickr-overrides.scss';
import '../stylesheets/slim-select-overrides.scss';
import '../stylesheets/react-filters.scss';
import '../stylesheets/react-icons.scss';
import '../stylesheets/goals.scss';
import '../stylesheets/tabs.scss';
import '../stylesheets/time-input.scss';
import '../stylesheets/input.scss';
import '../stylesheets/richtextInput.scss';
import '../stylesheets/react-pdf.scss';

import 'chartkick/highcharts';
import LocalTime from 'local-time';
import Rails from '@rails/ujs';
import ManageEmbeddedModeCookie from './embed_application';

import 'vanilla-nested';

window.Rails = Rails;
require('@rails/activestorage').start();
require('channels/');
/* eslint-enable import/no-unresolved */

// Check if the app is running in embedded mode
ManageEmbeddedModeCookie();

LocalTime.start();

window.Rails.start();

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

/* eslint-disable react-hooks/rules-of-hooks */
ReactRailsUJS.useContext(componentRequireContext);
/* eslint-enable react-hooks/rules-of-hooks */
