import { Controller } from 'stimulus';

/* eslint-disable no-param-reassign */
export default class extends Controller {
  static targets = ['category', 'goals', 'objectives'];

  static values = { goals: Array, objectives: Array };

  connect() {
    const category = this.categoryTarget.value;
    const goals_select = this.goalsTarget;
    const objective_select = this.objectivesTarget;
    if (category) {
      const selected_goal = goals_select.value;
      this.updateCategory();
      if (selected_goal) {
        this.selectOption(goals_select, selected_goal);
      }
      objective_select.querySelectorAll('.objective-select').forEach((select) => {
        const objectivesArray = this.objectivesForGoal(goals_select.value);
        const selected_objective = select.value;
        this.setOptions(select, objectivesArray);
        if (selected_objective) {
          this.selectOption(select, selected_objective);
        }
        objective_select.classList.remove('hidden');
      });
    }
  }

  updateCategory() {
    const category = this.categoryTarget.value;
    const goals_array = this.goalsForCategory(category);
    const select = this.goalsTarget;
    this.setOptions(select, goals_array);
    select.parentElement?.classList?.remove('hidden');
    select.parentElement?.parentElement?.classList?.remove('hidden');
  }

  goalsForCategory(category_id) {
    /* eslint-disable eqeqeq */
    // Note: type coercion intentional
    return this.goalsValue.filter((goal) => goal.treatment_goal_category_id == category_id);
    /* eslint-enable eqeqeq */
  }

  setOptions(select, options) {
    if (select) {
      select.innerHTML = '';
      select.options[0] = new Option();
      options.forEach((option) => {
        select.options[select.options.length] = new Option(option.description, option.id);
      });
    }
  }

  updateObjectives() {
    const goal = this.goalsTarget.value;
    const objectivesArray = this.objectivesForGoal(goal);
    const objectiveSelects = this.objectivesTarget.querySelectorAll('.objective-select');
    objectiveSelects.forEach((select) => {
      this.setOptions(select, objectivesArray);
    });
    this.objectivesTarget.classList.remove('hidden');
  }

  updateNewObjectiveForm() {
    const goal = this.goalsTarget.value;
    const objectivesArray = this.objectivesForGoal(goal);
    const objectiveSelects = this.objectivesTarget.querySelectorAll('.objective-select');
    const select = objectiveSelects[objectiveSelects.length - 1];
    this.setOptions(select, objectivesArray);
  }

  objectivesForGoal(goal_id) {
    /* eslint-disable eqeqeq */
    // Note: type coercion intentional
    return this.objectivesValue.filter((objective) => objective.goal_option_id == goal_id);
    /* eslint-enable eqeqeq */
  }

  selectOption(select, option) {
    select.querySelector(`[value="${option}"]`).selected = true;
  }
}
/* eslint-enable no-param-reassign */
