import { Controller } from 'stimulus';

import Rails from '@rails/ujs';

/* eslint-disable no-param-reassign */
export default class extends Controller {
  static targets = ['userTimeZoneIdentifier'];

  connect() {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const userTimeZoneIdentifier = this.userTimeZoneIdentifierTarget;
    const userId = userTimeZoneIdentifier.dataset.id;
    const data = {
      user: {
        id: userId,
        time_zone: timeZone,
      },
    };

    Rails.ajax({
      type: 'patch',
      url: `/users/${userId}`,
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        options.data = JSON.stringify(data);
        return true;
      },
    });
  }
}
/* eslint-enable no-param-reassign */
