import { Controller } from 'stimulus';
import moment from 'moment';

const defaultFormat = 'ddd, MMM D';

/**
 * Format the day buttons on the appointment selector page in a friendly way, accounting for the
 * local timezone.
 */
/* eslint-disable no-param-reassign */
export default class extends Controller {
  static targets = ['day'];

  connect() {
    Object.values(this.dayTargets).forEach((target) => {
      target.innerText = moment(target.dataset.day).calendar({
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        sameElse: defaultFormat,
        nextWeek: defaultFormat,
      });
    });
  }
}
/* eslint-enable no-param-reassign */
