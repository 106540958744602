import { Controller } from 'stimulus';
import LocalTime from 'local-time';
import consumer from '../channels/consumer';

/* eslint-disable import/no-unresolved */
require('channels');
/* eslint-enable import/no-unresolved */

LocalTime.start();

export default class extends Controller {
  static targets = ['actionHeading', 'actionMessage', 'card', 'declineCodeText', 'paymentButton', 'timestamp'];

  static values = { spaceId: String, accessToken: String };

  message_content_type = 'stripeDeclineCodeMessage';

  connect() {
    const controller = this;
    this.subscribeToPaymentResultChannel(controller);
  }

  subscribeToPaymentResultChannel(controller) {
    consumer.subscriptions.create('PaymentResultChannel', {
      received(data) {
        controller.handlePaymentResult(data);
      },
    });
  }

  handlePaymentResult(data) {
    // Note: type coercion intentional
    /* eslint-disable eqeqeq */
    if (data.payment_id == this.paymentButtonTarget.dataset.paymentId) {
      /* eslint-enable eqeqeq */
      this.lastAttempt = new Date(data.last_attempt);
      this.getMessageInfo(data.decline_code);
    }
  }

  getMessageInfo(declineCode) {
    const contentful = require('contentful');
    const client = contentful.createClient({
      space: this.spaceIdValue,
      accessToken: this.accessTokenValue,
    });

    const controller = this;

    client
      .getEntries({
        content_type: this.message_content_type,
        'fields.declineCode': declineCode,
      })
      .then((entries) => {
        if (entries.total > 0) {
          const messageInfo = entries.items[0].fields;
          controller.updatePaymentResult(messageInfo);
          /* eslint-disable eqeqeq */
        } else if (declineCode != 'default') {
          // Type coercion intentional
          /* eslint-enable eqeqeq */
          controller.getMessageInfo('default');
        }
      });
  }

  hidePaymentResult() {
    this.cardTarget.classList.add('hidden');
  }

  updatePaymentResult(messageInfo) {
    if (this.hasActionHeadingTarget) {
      this.actionHeadingTarget.textContent = messageInfo.adminActionHeading;
    }

    if (this.hasDeclineCodeTextTarget) {
      this.declineCodeTextTarget.textContent = messageInfo.declineCodeText;
    }

    if (this.hasActionMessageTarget) {
      this.actionMessageTarget.textContent = messageInfo.adminActionMessage;
    }

    if (this.hasTimestampTarget) {
      this.timestampTarget.textContent = LocalTime.strftime(this.lastAttempt, '%b %d, %Y at %l:%M %p %Z');
    }

    this.cardTarget.classList.remove('hidden');
  }
}
