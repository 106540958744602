import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button'];

  static values = { buttonText: String };

  update() {
    this.buttonTarget.value = this.buttonTextValue;
  }
}
