import SlimSelect from 'slim-select';
import StimulusSlimSelect from 'stimulus-slimselect';

export default class extends StimulusSlimSelect {
  connect() {
    this.before = [];
    this.slimselect = new SlimSelect({
      select: this.element,
      allowDeselectOption: true,
      closeOnSelect: false,
      beforeOnChange: () => {
        this.before = this.slimselect.selected();
      },
      onChange: (info) => {
        if (info.length === 0 || (!this.defaultWasSelected() && this.defaultIsSelected(info) && info.length > 1)) {
          this.selectDefaultOption();
          return false;
        }

        if (this.defaultWasSelected() && this.defaultIsSelected(info) && info.length > 1) {
          const options = info.filter((opt) => opt.value !== '');
          this.slimselect.set(options.map((opt) => opt.value));
          return false;
        }

        return true;
      },
    });

    if (this.element.value === '') this.selectDefaultOption();
  }

  selectDefaultOption() {
    this.slimselect.set(['']);
  }

  defaultWasSelected() {
    return this.before.includes('');
  }

  defaultIsSelected(values) {
    return values.map((e) => e.value).includes('');
  }
}
