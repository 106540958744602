import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['link'];

  static values = { id: String, fields: String };

  addField(event) {
    event.preventDefault();
    const link = this.linkTarget;
    const linkId = Number(this.idValue);
    let prevIndex;
    let regexp;
    let labelregexp;
    let newFields;

    if (link.previousElementSibling && !Number.isNaN(Number(link.previousElementSibling.dataset.index))) {
      prevIndex = Number(link.previousElementSibling.dataset.index);
    } else {
      prevIndex = -1;
    }

    if (linkId) {
      regexp = new RegExp(linkId, 'g');
      labelregexp = new RegExp(linkId + 1, 'g');
      newFields = this.fieldsValue.replace(regexp, prevIndex + 1).replace(labelregexp, prevIndex + 2);
      link.insertAdjacentHTML('beforebegin', newFields);
    }
  }
}
