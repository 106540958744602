import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['trigger', 'content'];

  expand() {
    this.triggerTarget?.classList?.add('open');
    this.contentTarget?.classList?.remove('hidden');
  }

  collapse() {
    this.triggerTarget?.classList?.remove('open');
    this.contentTarget?.classList?.add('hidden');
  }

  toggle() {
    if (this.triggerTarget.classList.contains('open')) {
      this.collapse();
    } else {
      this.expand();
    }
  }
}
