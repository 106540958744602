import { getCookieValue, setCookie, removeCookie } from '../helpers/Cookies';

const EMBEDDED_COOKIE_NAME = 'embedded_version';
const EMBEDDED_MODE_URL = '/embedded-mode';

function getCSRFToken() {
  const metaTag = document.querySelector('meta[name="csrf-token"]');
  return metaTag ? metaTag.content : null;
}

function manageEmbeddedModeCookie() {
  const embeddedCookieValue = getCookieValue(EMBEDDED_COOKIE_NAME);
  const isEmbedded = window.parent !== window;

  const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': getCSRFToken(),
  };

  if (isEmbedded && !embeddedCookieValue) {
    setCookie(EMBEDDED_COOKIE_NAME, '1');
    fetch(EMBEDDED_MODE_URL, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        embedded: true,
      }),
    });
  } else if (!isEmbedded && embeddedCookieValue) {
    removeCookie(EMBEDDED_COOKIE_NAME);
    fetch(EMBEDDED_MODE_URL, { method: 'DELETE', headers });
  }
}

export default manageEmbeddedModeCookie;
