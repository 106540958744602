import { Controller } from 'stimulus';
import consumer from '../channels/consumer';

require('@rails/activestorage').start();

/* eslint-disable import/no-unresolved */
require('channels');
/* eslint-enable import/no-unresolved */

/* eslint-disable no-param-reassign */
export default class extends Controller {
  static targets = [
    'uploaded',
    'confirmationStepLabel',
    'confirmationStepNumeral',
    'requestStepLabel',
    'requestStepNumeral',
    'awaiting',
    'received',
    'signatureButton',
  ];

  static values = { id: String };

  connect() {
    const requestSignatureController = this;
    this.subscribeToSignatureRequestChannel(requestSignatureController);
    this.subscribeToSignedDocumentChannel(requestSignatureController);
  }

  request() {
    const data = {
      memberId: this.signatureButtonTarget.dataset.memberId,
      signatureRequestId: this.signatureButtonTarget.dataset.signatureRequestId,
      document: this.signatureButtonTarget.dataset.document,
    };
    this.SignatureRequestChannel.perform('received', { data });
  }

  subscribeToSignedDocumentChannel(requestSignatureController) {
    consumer.subscriptions.create('SignedDocumentChannel', {
      received(data) {
        if (!!data.signature_request_id && !!requestSignatureController.idValue) {
          if (data.signature_request_id.toString() === requestSignatureController.idValue.toString()) {
            requestSignatureController.awaitingTarget.classList.add('hidden');
            requestSignatureController.receivedTarget.classList.remove('hidden');
            requestSignatureController.confirmationStepLabelTarget.classList.remove('admin-progress__label--current');
            requestSignatureController.confirmationStepLabelTarget.classList.add('admin-progress__label--completed');
            requestSignatureController.confirmationStepNumeralTarget.classList.remove(
              'admin-progress__numeral--current'
            );
            requestSignatureController.confirmationStepNumeralTarget.classList.add(
              'admin-progress__numeral--completed'
            );
          }
        }
      },
    });
  }

  subscribeToSignatureRequestChannel(requestSignatureController) {
    requestSignatureController.SignatureRequestChannel = consumer.subscriptions.create(
      { channel: 'SignatureRequestChannel', room: 'signature_request' },
      {
        received({ content }) {
          if (!!content.data.signatureRequestId && !!requestSignatureController.idValue) {
            if (content.data.signatureRequestId.toString() === requestSignatureController.idValue.toString()) {
              requestSignatureController.uploadedTarget.classList.add('hidden');
              requestSignatureController.awaitingTarget.classList.remove('hidden');
              requestSignatureController.requestStepLabelTarget.classList.remove('admin-progress__label--current');
              requestSignatureController.requestStepLabelTarget.classList.add('admin-progress__label--completed');
              requestSignatureController.requestStepNumeralTarget.classList.remove('admin-progress__numeral--current');
              requestSignatureController.requestStepNumeralTarget.classList.add('admin-progress__numeral--completed');
              requestSignatureController.confirmationStepNumeralTarget.classList.add(
                'admin-progress__numeral--current'
              );
              requestSignatureController.confirmationStepLabelTarget.classList.add('admin-progress__label--current');
            }
          }
        },
      }
    );
  }
}
/* eslint-enable no-param-reassign */
