import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['accountPartial', 'mainPartial', 'accountTab', 'mainTab'];

  connect() {
    const accountPartial = this.accountPartialTarget;
    accountPartial.classList.add('hidden');
  }

  showAccountTab() {
    const accountPartial = this.accountPartialTarget;
    const mainPartial = this.mainPartialTarget;
    const mainTab = this.mainTabTarget;
    const accountTab = this.accountTabTarget;

    mainPartial.classList.add('hidden');
    accountPartial.classList.remove('hidden');

    mainTab.classList.remove('active');
    accountTab.classList.add('active');
  }

  showMainTab() {
    const accountPartial = this.accountPartialTarget;
    const mainPartial = this.mainPartialTarget;
    const mainTab = this.mainTabTarget;
    const accountTab = this.accountTabTarget;

    mainPartial.classList.remove('hidden');
    accountPartial.classList.add('hidden');

    accountTab.classList.remove('active');
    mainTab.classList.add('active');
  }
}
