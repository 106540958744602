import { Controller } from 'stimulus';
import consumer from '../channels/consumer';

/* eslint-disable import/no-unresolved */
require('channels');
/* eslint-disable import/no-unresolved */

export default class InvitesController extends Controller {
  static changesMap = {
    delivered(controller, data) {
      controller.updateLabel(data.member_id, `Invite Again (${data.sent_count})`);
      controller.updateHistory(data.member_id, 'Delivered less than a minute ago', data.body);
    },

    failed(controller, data) {
      controller.updateHistory(data.member_id, 'Error', data.body);
    },

    received(controller, data) {
      controller.updateLabel(data.member_id, 'One moment...');
      controller.updateHistory(data.member_id, '', null);
    },

    rescheduled(controller, data) {
      controller.updateLabel(data.member_id, 'Re-Send Email');
      controller.updateHistory(data.member_id, 'SMS Scheduled', data.body);
    },

    sent(controller, data) {
      controller.updateLabel(data.member_id, `Invite Again (${data.sent_count})`);
      controller.updateHistory(data.member_id, 'Sent less than a minute ago', data.body);
    },

    undelivered(controller, data) {
      controller.updateHistory(data.member_id, 'Error', data.body);
    },
  };

  connect() {
    this.subscribeToInvitesChannel();
  }

  // Subscribe to the invite channel to update the status of invites as they change.
  subscribeToInvitesChannel() {
    const controller = this;

    consumer.subscriptions.create(
      { channel: 'InvitesChannel', room: 'invites' },
      {
        received(data) {
          controller.update(data);
        },
      }
    );
  }

  // Update the status for a single member given an invite update.
  update(data) {
    const changes = InvitesController.changesMap[data.status];
    if (changes) changes(this, data);
  }

  // Update an invite status label.
  updateLabel(member_id, text) {
    const inviteLabel = document.getElementById(`invite_action_${member_id}`);

    if (inviteLabel) {
      inviteLabel.innerText = text;
    }
  }

  updateHistory(member_id, text, body) {
    const label = document.querySelector(`#invite_history_${member_id} a`);
    if (label) label.innerText = text;

    if (body) {
      const list = document.querySelector(`#invite_history_${member_id} ul`);
      if (list) list.insertAdjacentHTML('afterbegin', body);
    }
  }
}
