import Flatpickr from 'stimulus-flatpickr';

require('flatpickr/dist/themes/airbnb.css');

export default class extends Flatpickr {
  // all flatpickr hooks are available as callbacks in your Stimulus controller
  yearChange(selectedDates, dateStr, instance) {
    const date = instance.parseDate(dateStr);
    date.setFullYear(instance.currentYear);
    instance.setDate(date);
  }

  monthChange(selectedDates, dateStr, instance) {
    const date = instance.parseDate(dateStr);
    date.setMonth(instance.currentMonth);
    instance.setDate(date);
  }
}
