import { Controller } from 'stimulus';

/* eslint-disable no-param-reassign */
export default class extends Controller {
  static targets = ['category', 'goals', 'goalText', 'goalOption'];

  static values = { goals: Array };

  updateCategory(event) {
    window.scrollTo(0, 0);
    const goals_array = this.goalsForCategory(event.currentTarget.dataset.id);
    const goalsElement = this.goalsTarget;
    this.setGoals(goalsElement, goals_array);
    goalsElement.parentElement?.classList?.remove('hidden');
    goalsElement.parentElement?.parentElement?.classList?.remove('hidden');
    this.categoryTarget.classList.add('hidden');
  }

  goalsForCategory(category_id) {
    return this.goalsValue.filter((goal) => toString(goal.treatment_goal_category_id) === toString(category_id));
  }

  setGoals(goalsElement, options) {
    let html = '';
    options.forEach((option) => {
      html = html.concat(
        `<div class="relative bg-blue-100 rounded-2xl py-6 px-5 flex flex-row mb-6 w-full leading-5">
        <input type="radio" class="radio-button--treatment-objective relative top-1 block" data-action="click->enable-button#enable" value=${option.id} name="treatment_goal[goal_option_id]" id="treatment_goal_option_${option.id}">
        <label for="treatment_goal_option_${option.id}" class="block ml-3 leading-6 text-base text-gray-700">${option.description} </label>
        </div>`
      );
    });

    if (goalsElement) {
      goalsElement.innerHTML = html;
    }
  }

  showGoalText() {
    if (this.goalsTarget.querySelector('input[type = radio]:checked')) {
      window.scrollTo(0, 0);
      this.goalTextTarget?.classList?.remove('hidden');
      this.goalsTarget?.parentElement?.classList?.add('hidden');

      if (this.goalOptionTarget) {
        this.goalOptionTarget.innerHTML = this.selectedGoalOption();
      }
    }
  }

  selectedGoalOption() {
    const id = document.querySelector('input[type = radio]:checked').value;
    return this.optionById(id).description;
  }

  optionById(id) {
    return this.goalsValue.find((goal) => toString(goal.id) === toString(id));
  }
}
/* eslint-enable no-param-reassign */
