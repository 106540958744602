function getCookieValue(cookieName) {
  const cookies = document.cookie.split('; ');
  const matchingCookie = cookies.find((cookie) => {
    const [name, _value] = cookie.split('=');
    return name === cookieName;
  });

  return matchingCookie ? matchingCookie.split('=')[1] : null;
}

function setCookie(cookieName, cookieValue, path = '/') {
  document.cookie = `${cookieName}=${cookieValue}; path=${path}; secure; SameSite=None;`;
}

function removeCookie(cookieName, path = '/') {
  document.cookie = `${cookieName}=; path=${path}; secure; SameSite=None;`;
}

export { getCookieValue, setCookie, removeCookie };
