import { Controller } from 'stimulus';
import ReactRailsUJS from 'react_ujs';

export default class extends Controller {
  static targets = ['modal', 'errors', 'form', 'bg'];

  showModal() {
    ReactRailsUJS.mountComponents();
    if (this.hasErrorsTarget) {
      this.errorsTarget.textContent = '';
    }

    this.modalTarget.classList.remove('hidden');

    if (this.hasBgTarget) {
      this.bgTarget.classList.remove('hidden');
    }
  }

  hideModal() {
    this.modalTarget.classList.add('hidden');

    if (this.hasBgTarget) {
      this.bgTarget.classList.add('hidden');
    }

    if (this.hasFormTarget) {
      this.formTarget.reset();
    }
  }

  error(event) {
    if (this.hasErrorsTarget) {
      [this.errorsTarget.textContent] = event.detail;
    }
  }
}
