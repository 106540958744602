import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['selectField', 'dateField', 'element'];

  updateField(event) {
    event.preventDefault();
    this.selectFieldTarget.removeAttribute('disabled');
  }

  updateDateField(event) {
    event.preventDefault();
    this.dateFieldTarget.classList.remove('hidden');
  }
}
