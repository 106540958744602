import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['password', 'password_confirmation', 'toggle'];

  toggle() {
    const checkbox = this.toggleTarget;
    const password = this.passwordTarget;
    const password_confirmation = this.password_confirmationTarget;

    if (checkbox.checked) {
      password.type = 'text';
      password_confirmation.type = 'text';
    } else {
      password.type = 'password';
      password_confirmation.type = 'password';
    }
  }
}
