import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button'];

  enable() {
    this.buttonTarget.classList.replace('btn--state-disabled', 'btn--primary');
  }

  enableIfValue(event) {
    if (event.currentTarget.value === '') {
      this.buttonTarget.classList.replace('btn--primary', 'btn--state-disabled');
      this.buttonTarget.setAttribute('disabled', 'true');
    } else {
      this.buttonTarget.classList.replace('btn--state-disabled', 'btn--primary');
      this.buttonTarget.removeAttribute('disabled');
    }
  }
}
