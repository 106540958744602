import { Controller } from 'stimulus';

/* eslint-disable no-param-reassign */
export default class extends Controller {
  static targets = ['objectiveOptions', 'intervention', 'modality', 'frequency', 'targetDate', 'objectiveText'];

  // From https://github.com/hotwired/stimulus/issues/200#issuecomment-434731830
  connect() {
    const event = document.createEvent('CustomEvent');
    event.initCustomEvent('treatment-objective-connected', true, true, null);
    this.element.dispatchEvent(event);
  }

  showIntervention() {
    if (this.objectiveOptionsTarget.querySelector('input[type = radio]:checked')) {
      window.scrollTo(0, 0);
      this.objectiveOptionsTarget.classList.add('hidden');
      this.interventionTarget.classList.remove('hidden');
      this.objectiveTextTargets.forEach((target) => {
        if (target) {
          target.innerText = this.selectedObjectiveOptionText();
        }
      });
    }
  }

  showModality() {
    if (this.interventionTarget.querySelector('input[type = radio]:checked')) {
      window.scrollTo(0, 0);
      this.interventionTarget.classList.add('hidden');
      this.modalityTarget.classList.remove('hidden');
    }
  }

  showFrequency() {
    if (this.modalityTarget.querySelector('input[type = radio]:checked')) {
      window.scrollTo(0, 0);
      this.modalityTarget.classList.add('hidden');
      this.frequencyTarget.classList.remove('hidden');
    }
  }

  showTargetDate() {
    if (this.frequencyTarget.querySelector('input[type = radio]:checked')) {
      window.scrollTo(0, 0);
      this.frequencyTarget.classList.add('hidden');
      this.targetDateTarget.classList.remove('hidden');
    }
  }

  selectedObjectiveOptionText() {
    return document.querySelector('input[type = radio]:checked').nextElementSibling.innerText;
  }

  navigateBack() {
    window.scrollTo(0, 0);
    const fields = [
      this.objectiveOptionsTarget,
      this.interventionTarget,
      this.modalityTarget,
      this.frequencyTarget,
      this.targetDateTarget,
    ];
    const index = this.currentFieldIndex(fields);
    if (index === 0) {
      window.history.back();
    } else {
      fields[index].classList.add('hidden');
      fields[index - 1].classList.remove('hidden');
    }
  }

  currentFieldIndex(fields) {
    return fields.findIndex((field) => !field.classList.contains('hidden'));
  }
}
/* eslint-enable no-param-reassign */
