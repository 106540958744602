import { Controller } from 'stimulus';
import DocumentRequestChannel from '../channels/document_request_channel';

require('@rails/activestorage').start();

/* eslint-disable import/no-unresolved */
require('channels');
/* eslint-enable import/no-unresolved */

export default class extends Controller {
  static targets = ['documentButton'];

  request() {
    const data = {
      memberId: this.documentButtonTarget.dataset.memberId,
      document: this.documentButtonTarget.dataset.document,
    };
    DocumentRequestChannel.perform('received', { data });
  }
}
