import { Controller } from 'stimulus';
import PaymentRequestChannel from '../channels/payment_request_channel';

require('@rails/activestorage').start();

/* eslint-disable import/no-unresolved */
require('channels');
/* eslint-enable import/no-unresolved */

export default class extends Controller {
  static targets = ['paymentButton'];

  request() {
    const data = {
      memberId: this.paymentButtonTarget.dataset.memberId,
      paymentId: this.paymentButtonTarget.dataset.paymentId,
      amount: this.paymentButtonTarget.dataset.amount,
      document: this.paymentButtonTarget.dataset.document,
    };
    PaymentRequestChannel.perform('received', { data });
  }
}
